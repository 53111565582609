import { render, staticRenderFns } from "./LastActivities.vue?vue&type=template&id=6a85544f&scoped=true&"
import script from "./LastActivities.vue?vue&type=script&lang=js&"
export * from "./LastActivities.vue?vue&type=script&lang=js&"
import style0 from "./LastActivities.vue?vue&type=style&index=0&id=6a85544f&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a85544f",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VDataTable,VList,VListItem,VListItemAvatar,VListItemContent,VListItemSubtitle,VListItemTitle,VSkeletonLoader,VSpacer})

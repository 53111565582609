export function handleActivityEs(activity) {
  const model = `${getModelEs(activity.subject_type)} #${activity.subject_id}`
  const action = `Acción realizada: ${getDescriptionEs(activity.description)}`
  const id = `Identificador: ${activity.id}`
  const timestamp = activity.created_at
  const changes = activity.changes
  return { id, model, action, timestamp, changes}
}
function getModelEs(modelPath) {
  switch (modelPath) {
    case 'App\\Models\\Activity': return 'Actividad'
    case 'App\\Models\\Company': return 'Empresa'
    case 'App\\Models\\CompanyData': return 'Asignación de datos de Empresa'
    case 'App\\Models\\Country': return 'País'
    case 'App\\Models\\Data': return 'Dato de la ficha de Empresa'
    case 'App\\Models\\Director': return 'Director'
    case 'App\\Models\\Meeting': return 'Reunión'
    case 'App\\Models\\Publication': return 'Publicación'
    case 'App\\Models\\StatementAccount': return 'Estado de Cuenta'
    case 'App\\Models\\Upload': return 'Archivo multimedia'
    case 'App\\Models\\User': return 'Usuario'
    default: return '*'

  }
}
function getDescriptionEs(desc) {
  const strings = desc.split('_')
  switch (strings[0]) {
    case 'created': return 'Creación'
    case 'updated': return 'Actualización'
    case 'deleted': return 'Sustracción'
    default: return '*'

  }
}
export function handleActivityEn(activity) {
  const model = `${getModelEn(activity.subject_type)} #${activity.subject_id}`
  const action = `Action performed: ${getDescriptionEn(activity.description)}`
  const id = `Identifier: ${activity.id}`
  const timestamp = activity.created_at
  const changes = activity.changes
  return { id, model, action, timestamp, changes}
}
function getModelEn(modelPath) {
  switch (modelPath) {
    case 'App\\Models\\Activity': return 'Activity'
    case 'App\\Models\\Company': return 'Company'
    case 'App\\Models\\CompanyData': return 'Company Public Data'
    case 'App\\Models\\Country': return 'Country'
    case 'App\\Models\\Data': return 'Data Form'
    case 'App\\Models\\Director': return 'Director'
    case 'App\\Models\\Meeting': return 'Meeting'
    case 'App\\Models\\Publication': return 'Publication'
    case 'App\\Models\\StatementAccount': return 'Statement Account'
    case 'App\\Models\\Upload': return 'Upload'
    case 'App\\Models\\User': return 'User'
    default: return '*'

  }
}
function getDescriptionEn(desc) {
  const strings = desc.split('_')
  switch (strings[0]) {
    case 'created': return 'Created'
    case 'updated': return 'Updated'
    case 'deleted': return 'Deleted'
    default: return '*'

  }
}
export function handleActivityPt(activity) {
  const model = `${getModelPt(activity.subject_type)} #${activity.subject_id}`
  const action = `Medidas tomadas: ${getDescriptionPt(activity.description)}`
  const id = `Identificador: ${activity.id}`
  const timestamp = activity.created_at
  const changes = activity.changes
  return { id, model, action, timestamp, changes}
}
function getModelPt(modelPath) {
  switch (modelPath) {
    case 'App\\Models\\Activity': return 'Exercício'
    case 'App\\Models\\Company': return 'Empresa'
    case 'App\\Models\\CompanyData': return 'Dados Públicos da Empresa'
    case 'App\\Models\\Country': return 'País'
    case 'App\\Models\\Data': return 'Dados do ficheiro da empresa'
    case 'App\\Models\\Director': return 'Director'
    case 'App\\Models\\Meeting': return 'Reunião'
    case 'App\\Models\\Publication': return 'Publicação'
    case 'App\\Models\\StatementAccount': return 'Estado de Cuenta'
    case 'App\\Models\\Upload': return 'Arquivo multimídia'
    case 'App\\Models\\User': return 'Usuário'
    default: return '*'

  }
}
function getDescriptionPt(desc) {
  const strings = desc.split('_')
  switch (strings[0]) {
    case 'created': return 'Criação'
    case 'updated': return 'Atualização'
    case 'deleted': return 'Subtração'
    default: return '*'

  }
}
